.Zoinks .Toast-wrapper {
  font-size: 0.7rem;

  @media (min-width: 15px / (0.07 * 0.7)) {
    font-size: 15px;
  }
}

.Toast-wrapper {
  animation: slide-up 0.3s cubic-bezier(0, 0.4, 1, 1);
  bottom: 0;
  box-sizing: border-box;
  font-size: 0.6rem;
  padding: 1em;
  position: absolute;
  text-align: center;
  width: 100%;

  @media (min-width: 15px / (0.08 * 0.6)) {
    font-size: 15px;
  }
}

.Toast {
  align-items: center;
  background: #fff;
  border-radius: 9999px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.6);
  color: #000;
  display: inline-grid;
  font-family: Roboto;
  grid-template-columns: auto 1fr;
  text-shadow: none;
  text-transform: none;
  text-align: left;
}

.Toast-wrapper--expired {
  animation: slide-down 0.3s cubic-bezier(0.4, 0, 1, 1);
  transform: translateY(100%);
}

.Toast-icon {
  height: 2em;
  width: 2em;
  padding: 1em;
}

.Toast-message {
  padding: 0.5em calc(0.5em + 1em) 0.5em 0.5em;
}

@keyframes slide-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
