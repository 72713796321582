.WumpaHunt {
  align-items: center;
  background: #0074b6;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 0.6rem 0.8rem;
}

.WumpaHunt > span {
  letter-spacing: 0.05em;
}

.WumpaHunt-icon {
  cursor: pointer;
  filter: brightness(0) opacity(0.25);
  height: 1.2rem;
  margin-right: 0.8rem;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

.WumpaHunt-icon--active {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
}
