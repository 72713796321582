.TimeEntries {
  background: #017cc2;
  display: grid;
  overflow: auto;
}

.TimeEntries-entry {
  align-items: center;
  display: grid;
  gap: 0.8rem;
  grid-template-columns: auto 1fr auto;
  padding: 0.8rem;
}

.TimeEntries-entry:nth-last-child(2n) {
  background: #0084c7;
}

.TimeEntries-removeEntry {
  background: linear-gradient(
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.4)
  );
  box-shadow: var(--backdrop) rgba(0, 0, 0, 0.25);
  color: #004f8e;
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
  text-shadow: none;
}

.TimeEntries-wumpaHunt {
  cursor: pointer;
  filter: brightness(0) opacity(0.25);
  height: 1.2rem;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

.TimeEntries-wumpaHunt--selected {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
}
