.Grid {
  display: grid;
  grid-template-areas:
    "time-remaining time-remaining"
    "wumpa-hunt wumpa-hunt"
    "time-entries time-entries"
    "time-input add-time";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto auto;
  height: 100%;
}

.Grid-timeRemaining {
  grid-area: time-remaining;
}

.Grid-timeEntries {
  grid-area: time-entries;
}

.Grid-toasts {
  grid-area: time-entries;
  overflow: hidden;
  pointer-events: none;
  position: relative;
}

.Grid-wumpaHunt {
  grid-area: wumpa-hunt;
}
